import React from 'react';
import Seo from 'components/seo';
import Layout from 'components/layout';
import Banner from 'redz_section/banner';
// import Services from 'sections/services';
// import VideoCalling from 'sections/video-calling';
// import Pricing from 'sections/pricing';
// import Clients from 'sections/clients';
import Blog from 'sections/blog';
import Faq from 'sections/faq';
// import Support from 'sections/support-team';

export default function IndexPage() {
  return (
    <Layout>
      <Seo
        title="Redz | Earn Crypto by Creating Videos"
        description="Direct Virtual Reality cloud streaming platform. Opening the gateway to the metaverse."
      />
      <Banner />
      {/* <Services />
      <VideoCalling /> */}
      {/* <Pricing /> */}
      {/* <Clients /> */}
      <Blog />
      <Faq />
      {/* <Support /> */}
    </Layout>
  );
}
