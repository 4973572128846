/** @jsx jsx */
import { jsx, Box, Grid, Container, Button, Heading, Text, Embed } from 'theme-ui';
// import Button from '../components/button';
import { rgba } from 'polished';
// import { useStaticQuery, graphql } from 'gatsby';
// import { IoIosArrowForward } from 'react-icons/io';
// import Image from 'components/image';

const Banner = () => {
  // const data = useStaticQuery(graphql`
  //   query {
  //     illustration: file(relativePath: { eq: "video-calling.png" }) {
  //       childImageSharp {
  //         fluid(maxWidth: 1000) {
  //           ...GatsbyImageSharpFluid
  //         }
  //       }
  //     }
  //   }
  // `);
  return (
    <section id="home" sx={styles.section}>
      <Container sx={styles.container}>
        <Grid sx={styles.grid}>
          <Box as="figure" sx={styles.illustration}>
            {/* <Image
              src={data.illustration.childImageSharp.fluid}
              alt="illustration"
            /> */}
            <Embed src="https://www.youtube.com/embed/gtOMP0XyXFg" />
          </Box>
          <Box sx={styles.content}>
            {/* <div sx={styles.newsUpdate}>
              <span>NEWS</span>
              Redz Beta Release Program &amp; Registration Started{' '}
              <IoIosArrowForward
                color={rgba('#02073E', 0.3)}
                size="14px"
                sx={{ ml: 1 }}
              />
            </div> */}
            <Heading as="h1">
              Create Videos and Earn Cryptocurrency 
            </Heading>
            <Text as="p">
            Redz is a Augmented Reality social media application where you can create & share - Short Videos, NFTs and Metaverse 360° Media, for which you can earn Cryptocurrency based on your popularity of your content.
            </Text>
            <Text as="p">
              Start earning Cryptocurrency with just 500 followers or equivalent video reach. An oppourtunity not to be missed! Register below
            </Text>
            <Heading as="h3">
              Registration closed! We have closed our Beta program for now.
            </Heading>
            {/* <div sx={styles.buttonGroup}>
               <Heading as="h2">Pre-Book Now to Skip the Waiting List</Heading>
              <br />
              <Button onClick={(e) => {window.location.href = "https://rzp.io/l/YzlC22PZx"}} className="primary" >Pre-Book Now</Button>
              <br />
              <br /> 
               <Heading as="h2">Register Now for the Waiting List</Heading>
              <br />
              <Button onClick={(e) => {window.location.href = "https://forms.gle/RfBtuWdPGJuPV3Ef7"}} className="white" >Register Now</Button>
            </div> */ }
          </Box>
        </Grid>
      </Container>
    </section>
  );
};

export default Banner;

const styles = {
  section: {
    background: `linear-gradient(180deg, rgba(255, 254, 252, 0.5) 0%, #FEFAF5 100%)`,
    pt: [8, null, null, null, 10, 0],
    pb: [8, null, null, null, 10, 0],
  },
  container: {
    px: [3, null, null, 6],
  },
  grid: {
    alignItems: ['center'],
    gridTemplateColumns: ['1fr', null, null, null, '1fr 1fr'],
    minHeight: [null, null, null, null, '45vh', '100vh'],
    pt: [15, null, null, 17, 12],
  },
  content: {
    textAlign: ['center', null, null, null, 'left'],
    h1: {
      color: 'textSecondary',
      fontWeight: 'bold',
      fontSize: [8, null, null, null, 12, 14, 15],
      lineHeight: [1.33, null, null, null, 1.36],
      letterSpacing: 'heading',
    },
    p: {
      color: 'textSecondary',
      fontSize: [1, null, null, 2],
      lineHeight: 2.62,
      maxWidth: 480,
      mx: [null, null, null, 'auto', 'unset'],
      marginTop: [1, null, null, 3],
    },
  },
  newsUpdate: {
    backgroundColor: 'white',
    boxShadow: '0px 2px 8px rgba(20, 20, 20, 0.25)',
    borderRadius: 50,
    padding: '4px 15px 4px 5px',
    display: 'inline-flex',
    alignItems: 'center',
    color: rgba('#02073E', 0.9),
    fontSize: ['12px', null, null, 1],
    mb: 4,
    textAlign: 'left',
    span: {
      backgroundColor: 'primary',
      color: 'white',
      fontSize: ['10px', null, null, '13px'],
      fontWeight: 700,
      display: 'inline-flex',
      minHeight: 24,
      alignItems: 'center',
      px: '11px',
      borderRadius: 50,
      mr: '12px',
    },
  },
  buttonGroup: {
    mt: [6, null, null, null, 10],
    button: {
      minHeight: 45,
      px: ['17px', 4],
    },
    '.primary':{
      backgroundColor: 'red',
      borderRadius: 5,
    },
    '.primary:hover':{
      backgroundColor: '#1D1B1B',
      color: 'white'
    },
    '.white': {
      backgroundColor: '#1D1B1B',
      borderRadius: 5,
      boxShadow: '0px 4px 6px rgba(125, 128, 170, 0.08)',
      color: 'white'
      // fontWeight: 500,
      // ml: 3,
      
    },
    '.white:hover':{
      backgroundColor: 'red',
      color: 'white'
    }
  },
  illustration: {
    maxWidth: [null, null, null, '80%', 'none'],
    mx: [null, null, null, 'auto', 'unset'],
    mt: [6, null, null, null, 0],
  },
};
